// Estado inicial do módulo de alertas
const state = {
  message: '',
  type: '',
  visible: false
};

// Mutações para modificar o estado
const mutations = {
  // Muda o estado para mostrar o alerta
  SHOW_ALERT(state, payload) {
    state.message = payload.message;
    state.type = payload.type || 'success'; // Se o tipo não for fornecido, o padrão é 'success'
    state.visible = true;
  },
  // Muda o estado para esconder o alerta
  HIDE_ALERT(state) {
    state.visible = false;
  }
};

// Ações para disparar mutações (podem ser assíncronas)
const actions = {
  showAlert({ commit }, { message, type, time = 5000 }) {
    commit('SHOW_ALERT', { message, type });
    setTimeout(() => {
      commit('HIDE_ALERT');
    }, time);
  },

  hideAlert({ commit }) {
    commit('HIDE_ALERT');
  }
};

// Getters para acessar o estado
const getters = {
  // Retorna a mensagem do alerta
  alertMessage: state => state.message,
  // Retorna o tipo do alerta
  alertType: state => state.type,
  // Retorna se o alerta está visível ou não
  alertVisible: state => state.visible
};

// Exporta o módulo de alertas
export default {
  namespaced: true, // Certifique-se de que esta linha está presente
  state,
  mutations,
  actions,
  getters
};
